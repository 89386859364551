<template>
  <div>
    <trac-loading v-if="loading" />
    <div v-else>
      <div
        class="single-category small-shadow bg-white rounded-lg card-gradient cursor-pointer py-6 px-5 mb-12"
      >
        <h1 class="text-2xl font-semibold mb-6">{{ subCategory.name }}</h1>
        <p>
          {{ subCategory.product_count }} Product{{
            subCategory.product_count > 1 ? "s" : ""
          }}
        </p>
      </div>

      <sub-category-details :sub-category="subCategory" />

      <category-items v-model="searchedItem" :products="subCategory.products" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import SubCategoryDetails from "./SubCategoryDetails.vue";
import CategoryItems from "../CategoryItems.vue";

export default {
  name: "SubCategory",
  components: { SubCategoryDetails, CategoryItems },
  data() {
    return {
      loading: false,
      searchedItem: "",
    };
  },
  created() {
    this.fetchSubCategory();
  },
  computed: {
    ...mapGetters({
      category: ["GET_PRODUCT_CATEGORY"],
    }),
    subCategoryId() {
      return this.$route.params.sub_id;
    },
    subCategory() {
      return this.category.sub_category_products.find(
        (subCat) => subCat.id === this.subCategoryId
      );
    },
  },
  methods: {
    ...mapActions(["FETCH_PRODUCTS_CATEGORY"]),
    async fetchSubCategory() {
      this.loading = true;
      try {
        const res = await this.FETCH_PRODUCTS_CATEGORY(this.$route.params.id);
      } catch (error) {
        return eventBus.$emit("trac-alert", {
          message: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
